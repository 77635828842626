html,
body {
  padding: 0;
  margin: 0;
  background-color: #df8591 !important;
  /* min-height: 100vh !important; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.font-pc {
  font-family: 'PyeongChangPeace-Light' !important;
}

.font-is, .ant-collapse-header-text, .ant-tabs-tab-btn {
  font-family: 'InfinitySans-RegularA1' !important;
}

.admin {
  font-family: 'InfinitySans-RegularA1' !important;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.button-group {
  display: flex;
}
.button-group button {
  margin: 0 2px;
}

@font-face {
  font-family: 'PyeongChangPeace-Light';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2206-02@1.0/PyeongChangPeace-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'InfinitySans-RegularA1';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/InfinitySans-RegularA1.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Antd Custom Design */

.ant-tabs-top > .ant-tabs-nav::before {
  border: 0 !important
}

.ant-tabs-tab {
  color: white !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3553b2 !important;
}
.ant-tabs-ink-bar {
  background: #3553b2 !important;
}

.ant-btn.ant-btn-background-ghost:hover, .ant-btn.ant-btn-background-ghost:focus {
  color: yellow !important;
  border-color: yellow !important;
}

.ant-picker-calendar-header {
  background-color: #5DA3C2;
}

#schedule-table th, #schedule-table td {
  text-align: center;
}

#iframe-container {
  width: 100vw !important;
  overflow: hidden !important;
}

#iframe-container > iframe {
  margin: 0 auto;
}

#youtube-table > tbody > tr:nth-child(1), #youtube-table > tbody > tr:nth-child(2), #youtube-table > tbody > tr:nth-child(3) {
  background-color: rgba(0,0,0,0.05) !important;
}

.ant-picker-calendar-mode-switch {
  display: none;
}
.ant-picker-calendar-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.site-calendar-demo-card {
  width: 100%;
}

.ant-picker-calendar-date-content {
  position: absolute;
  top: -12px;
  right: -12px;
}

.ant-badge-status-dot {
  width: 10px;
  height: 10px;
  z-index: 1000;
}

.ant-card-meta-title {
  overflow: auto;
  white-space: unset;
}